export const GET_ALL_ADDRESSES = "get_all_addresses";
export const GET_ALL_ADDRESSES_SUCCESS = "get_all_addresses_success";
export const GET_ADDRESS_BY_ID = "get_address_by_id";
export const GET_ADDRESS_BY_ID_SUCCESS = "get_address_by_id_success";
export const GET_SUPPORTED_CARRIERS = "get_supported_carriers";
export const GET_SUPPORTED_CARRIERS_SUCCESS = "get_supported_carriers_success";
export const SET_DEFAULT_ADDRESS = "set_default_address";
export const ADD_ADDRESS = "add_address";
export const UPDATE_ADDRESS = "update_address";
export const DELETE_ADDRESS = "delete_address";

export const GET_ALL_CATEGORY_ADDRESSES = "get_all_category_addresses";
export const GET_ALL_CATEGORY_ADDRESSES_SUCCESS = "get_all_category_addresses_success";
