const ShopIcon = ({ extraClass = "", width = "30", height = "30", fill = "#fff" }) => (
  // TODO: Fill should use #fff
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.3596 8.27L22.0696 5.5C21.6496 2.48 20.2796 1.25 17.3497 1.25H14.9896H13.5097H10.4697H8.98965H6.58965C3.64965 1.25 2.28965 2.48 1.85965 5.53L1.58965 8.28C1.48965 9.35 1.77965 10.39 2.40965 11.2C3.16965 12.19 4.33965 12.75 5.63965 12.75C6.89965 12.75 8.10965 12.12 8.86965 11.11C9.54965 12.12 10.7097 12.75 11.9997 12.75C13.2896 12.75 14.4197 12.15 15.1096 11.15C15.8797 12.14 17.0696 12.75 18.3096 12.75C19.6396 12.75 20.8396 12.16 21.5896 11.12C22.1896 10.32 22.4597 9.31 22.3596 8.27Z"
      fill="white"
    />
    <path
      d="M11.3511 16.6602C10.0811 16.7902 9.12109 17.8702 9.12109 19.1502V21.8902C9.12109 22.1602 9.34109 22.3802 9.61109 22.3802H14.3811C14.6511 22.3802 14.8711 22.1602 14.8711 21.8902V19.5002C14.8811 17.4102 13.6511 16.4202 11.3511 16.6602Z"
      fill="white"
    />
    <path
      d="M21.3689 14.4001V17.3801C21.3689 20.1401 19.1289 22.3801 16.3689 22.3801C16.0989 22.3801 15.8789 22.1601 15.8789 21.8901V19.5001C15.8789 18.2201 15.4889 17.2201 14.7289 16.5401C14.0589 15.9301 13.1489 15.6301 12.0189 15.6301C11.7689 15.6301 11.5189 15.6401 11.2489 15.6701C9.46891 15.8501 8.11891 17.3501 8.11891 19.1501V21.8901C8.11891 22.1601 7.89891 22.3801 7.62891 22.3801C4.86891 22.3801 2.62891 20.1401 2.62891 17.3801V14.4201C2.62891 13.7201 3.31891 13.2501 3.96891 13.4801C4.23891 13.5701 4.50891 13.6401 4.78891 13.6801C4.90891 13.7001 5.03891 13.7201 5.15891 13.7201C5.31891 13.7401 5.47891 13.7501 5.63891 13.7501C6.79891 13.7501 7.93891 13.3201 8.83891 12.5801C9.69891 13.3201 10.8189 13.7501 11.9989 13.7501C13.1889 13.7501 14.2889 13.3401 15.1489 12.6001C16.0489 13.3301 17.1689 13.7501 18.3089 13.7501C18.4889 13.7501 18.6689 13.7401 18.8389 13.7201C18.9589 13.7101 19.0689 13.7001 19.1789 13.6801C19.4889 13.6401 19.7689 13.5501 20.0489 13.4601C20.6989 13.2401 21.3689 13.7201 21.3689 14.4001Z"
      fill="white"
    />
  </svg>
);

export default ShopIcon;
