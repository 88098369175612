import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton, Box } from "@mui/material";

// Components
import BottomModal from "./BottomModal";
import BottomFilterModal from "./BottomFilterModal";

// Icons
// import MenuIcon from "shared/Icons/mobile-icons/MenuIcon";
// import NotificationsIcon from "shared/Icons/mobile-icons/NotificationsIcon";
import BackArrowIcon from "shared/Icons/mobile-icons/BackArrowIcon";
import SearchIcon from "shared/Icons/mobile-icons/SearchIcon";
// import LocationIcon from "shared/Icons/mobile-icons/LocationIcon";
import HeaderSettingIcon from "shared/Icons/mobile-icons/HeaderSettingIcon";
import EditProfileIcon from "shared/Icons/mobile-icons/EditProfileIcon";
// import ChangePasswordIcon from "shared/Icons/mobile-icons/ChangePasswordIcon";
import LogoutIcon from "shared/Icons/mobile-icons/LogoutIcon";
// import HeaderFiltterIcon from "shared/Icons/mobile-icons/HeaderFiltterIcon";
// import ConnectWallet from "shared/Icons/mobile-icons/ConnectWallet";
import { useDispatch, useSelector } from "react-redux";
import ThirdWebConnectButton from "views/auth/login/component/thirdWebConnectButton";
import { useActiveWallet, useDisconnect } from "thirdweb/react";
import { logout } from "redux/auth/actions";

const MainHeader = () => {
  // States
  const [open, setOpen] = useState(false);
  const [ProfileBottomModal, setProfileBottomModal] = useState(false);
  const [filtersModal, setFiltersModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  // Use Location
  const { pathname } = useLocation();
  const account = useActiveWallet();
  const { disconnect } = useDisconnect();

  // Use Navigation
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Location Modal Handle
  // const locationHandle = () => setOpen(true);
  const locationHandleClose = () => setOpen(false);

  // Profile Bottom Modal
  const ProfileHandle = () => setProfileBottomModal(true);
  const ProfileHandleClose = () => setProfileBottomModal(false);

  // Filters Modal Handle
  // const FiltersOpenHandle = () => setFiltersModal(true);
  const FiltersHandleClose = () => setFiltersModal(false);

  // Profile Edit Handle
  const ProfileEditHandle = () => {
    navigate("/creatorProfile-edit");
    setProfileBottomModal(false);
  };

  const handleLogout = async () => {
    try {
      if (account) {
        await account.disconnect();
        disconnect(account);
      }
      dispatch(logout());
      navigate("/login");
      setProfileBottomModal(false);

      // window.location.href = "/login"
    } catch (err) {
      console.error(err);
    }
  };

  // Profile Bottom Modal List
  const profileBottomModalList = [
    {
      icon: <EditProfileIcon />,
      title: "Edit Profile",
      onclick: ProfileEditHandle
    },
    // {
    //   icon: <ChangePasswordIcon />,
    //   title: "Change Password",
    //   onclick: ProfileChangePasswordHandle
    // },
    {
      icon: <LogoutIcon />,
      title: "Logout",
      onclick: handleLogout
    }
  ];

  const excludedPaths = [
    "/marketplace",
    "/login",
    "/addresses",
    "/add-address",
    "/edit-address",
    "/creatorProfile-edit"
  ];

  return (
    <>
      {!excludedPaths.includes(pathname) && (
        <header className="main-header">
          {/* Menu Button */}
          {
            pathname.includes("productDetails") ||
            pathname === "/creatorProfile" ||
            pathname === "/creatorProfile-edit" ? (
              <IconButton className="menu-btn" onClick={() => navigate(-1)}>
                <BackArrowIcon />
              </IconButton>
            ) : (
              <IconButton className="menu-btn">
                <SearchIcon />
              </IconButton>
            )
            // <IconButton className="menu-btn"><MenuIcon /></IconButton>
          }
          {/* Menu Button End */}

          {/* Header Right Buttons */}
          {pathname === "/creatorProfile" ? (
            <Box className="header-right-btns">
              <IconButton className="menu-btn" onClick={ProfileHandle}>
                <HeaderSettingIcon />
              </IconButton>
            </Box>
          ) : (
            <Box className="header-right-btns">
              {/* <IconButton className="menu-btn" onClick={locationHandle}>
                <LocationIcon />
              </IconButton> */}
              {/* TODO: remove or use it */}
              {/* <IconButton className="menu-btn">
            <Badge badgeContent={4} color="primary">
            <NotificationsIcon color="action" />
            </Badge>
          </IconButton> */}

              {user && user?.isVerified ? (
                <ThirdWebConnectButton />
              ) : (
                <IconButton className="menu-btn login-btn" onClick={() => navigate("/login")}>
                  Login
                </IconButton>
              )}
            </Box>
          )}
          {/* Header Right Buttons End */}

          {/* Location Modal */}
          <BottomModal open={open} handleClose={locationHandleClose} />
          {/* Location Modal End */}

          {/* Profile Bottom Modal */}
          <BottomModal
            open={ProfileBottomModal}
            handleClose={ProfileHandleClose}
            modalBottomList={profileBottomModalList}
          />
          {/* Profile Bottom Modal End */}

          {/* Filters Modal */}
          <BottomFilterModal open={filtersModal} handleClose={FiltersHandleClose} />
          {/* Filters Modal End */}
        </header>
      )}
    </>
  );
};

export default MainHeader;
