import React from 'react';
import { Box, Modal, Typography } from "@mui/material";

// Components
import FiltersTabs from './FiltersTabs';

// Icons
import CloseIcon from 'shared/Icons/mobile-icons/CloseIcon';

const BottomFilterModal = ({ open, handleClose }) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-bottom modal-bottom-filters"
    >
      <Box className="modal-bottom-content">
        <Box className="modal-bottom-head">
          <Typography className="title">Filters</Typography>
          <span className="icon" onClick={handleClose}>
            <CloseIcon extraClass="icon-svg" />
          </span>
        </Box>
        <FiltersTabs />
      </Box>
    </Modal>
  );
};

export default BottomFilterModal;
