import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box, Button } from '@mui/material';

// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';

// Icons
import TickIcon from 'shared/Icons/mobile-icons/TickIcon';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className="filters-tabs-content-wrap"
    >
      {value === index && (
        <Box className="filters-tabs-content">{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function FiltersTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      // sx={{ flexGrow: 1, display: 'flex', height: 224 }}
      className="filters-tabs-wrap"
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
        className="filters-tabs"
      >
        <Tab label="Sort by" {...a11yProps(0)} />
        <Tab label="Cars" {...a11yProps(1)} />
        <Tab label="Watches" {...a11yProps(2)} />
        <Tab label="Mobiles" {...a11yProps(3)} />
        <Tab label="Drinks" {...a11yProps(4)} />
        <Tab label="NFT’s" {...a11yProps(5)} />
        <Tab label="Shirt" {...a11yProps(6)} />
        <Tab label="Tax" {...a11yProps(7)} />
        <Tab label="Luxary collectibles and gifts" {...a11yProps(8)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Typography className="title">Sort By</Typography>
        <Box className="filters-sort-wrap">
          <Box className="filters-btns-wrap">
            <Box className="filters-btn">
              <TickIcon />
              Featured
            </Box>
            <Box className="filters-btn selected">
              <TickIcon />
              lorem
            </Box>
            <Box className="filters-btn">
              <TickIcon />
              Featured
            </Box>
            <Box className="filters-btn selected">
              <TickIcon />
              Lorem Ipsum dolor sit
            </Box>
            <Box className="filters-btn">
              <TickIcon />
              Featured
            </Box>
            <Box className="filters-btn">
              <TickIcon />
              lorem
            </Box>
            <Box className="filters-btn">
              <TickIcon />
              Featured
            </Box>
            <Box className="filters-btn">
              <TickIcon />
              Lorem Ipsum dolor sit
            </Box>
            <Box className="filters-btn">
              <TickIcon />
              Featured
            </Box>
            <Box className="filters-btn selected">
              <TickIcon />
              lorem
            </Box>
            <Box className="filters-btn">
              <TickIcon />
              Featured
            </Box>
            <Box className="filters-btn selected">
              <TickIcon />
              Lorem Ipsum dolor sit
            </Box>
          </Box>
          <Button
            className="filters-apply-btn"
            variant="outlined"
            color="inherit"
          >
            Apply
          </Button>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Watches
      </TabPanel>
      <TabPanel value={value} index={3}>
        Mobiles
      </TabPanel>
      <TabPanel value={value} index={4}>
        Drinks
      </TabPanel>
      <TabPanel value={value} index={5}>
        NFT’s
      </TabPanel>
      <TabPanel value={value} index={6}>
        Shirt
      </TabPanel>
      <TabPanel value={value} index={7}>
        Tax
      </TabPanel>
      <TabPanel value={value} index={8}>
        Luxary collectibles and gifts
      </TabPanel>
    </Box>
  );
}