import { Helmet } from "react-helmet";
import { Link, useOutletContext } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import SocialSignUpForm from "../signUp/component/SocialSignUpForm";

const SocialLogin = () => {
  const theme = useOutletContext();

  return (
    <Box className="access-auth-wrap">
      {/* Helmet */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign Up</title>
      </Helmet>
      {/* Helmet End */}

      {/* Auth Form Wrap */}
      <Grid container direction="column">
        {/* Auth Heading */}
        <Box>
          <Typography className="auth-heading" variant="subtitle1">
            Sign Up
          </Typography>
        </Box>
        {/* Auth Heading End */}

        {/* Auth Form */}
        <SocialSignUpForm theme={theme} />
        {/* Auth Form End */}

        {/* Already Wrap */}
        <Box className="already-text">
          Already have an account?{" "}
          <Link to="/mobile-login" className="link">
            Sign In
          </Link>
        </Box>
        {/* Already Wrap End */}
      </Grid>
      {/* Auth Form Wrap End */}
    </Box>
  );
};

export default SocialLogin;
