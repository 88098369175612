import React from 'react';
import { Box, Modal, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

// Icons
import LocationIcon from "shared/Icons/mobile-icons/LocationIcon";
import GlobalIcon from 'shared/Icons/mobile-icons/GlobalIcon';

const BottomModal = ({ 
  open, 
  handleClose,
  modalBottomList = [
    {
      icon: <GlobalIcon />,
      title: 'Show all items',
      onclick: null
    },
    {
      icon: <LocationIcon />,
      title: 'Available in my country',
      onclick: null
    }
  ],
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-bottom"
    >
      <Box className="modal-bottom-content">
        <nav aria-label="main mailbox folders">
          <List>
            {modalBottomList.map((item, i) => (
              <ListItem disablePadding key={i}>
                <ListItemButton onClick={item.onclick}>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>
    </Modal>
  );
};

export default BottomModal;
