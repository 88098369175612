import { lazy } from "react";
import Loadable from "ui-component/Loadable";

// Layout
// import AccessLayoutMobile from "layout/AccessLayoutMobile";
import SocialLogin from "views/auth/mobileAuth/socialLogin";
import MainLayoutMobile from "layout/MainLayoutMobile";

// Pages
const Login = Loadable(lazy(() => import("views/auth/mobileAuth/login")));
const SignUp = Loadable(lazy(() => import("views/auth/mobileAuth/signUp")));
const ForgotPassword = Loadable(lazy(() => import("views/auth/mobileAuth/forgotPassword")));
const VerifyCode = Loadable(lazy(() => import("views/auth/mobileAuth/verifyCode")));
// const Email = Loadable(lazy(() => import("views/auth/mobileAuth/signUp/email")));
const Dashboard = Loadable(lazy(() => import("views/auth/mobileAuth/dashboard")));
const ProductDetail = Loadable(lazy(() => import("views/auth/mobileAuth/productDetail")));
const MetaWallet = Loadable(lazy(() => import("views/auth/mobileAuth/metaWallet")));
const VerifyEmailBefore = Loadable(lazy(() => import("views/auth/emailVerifyBefore")));
const VerifyEmail = Loadable(lazy(() => import("views/auth/verifyEmail")));

const MobileRoutes = {
  path: "/",
  element: <MainLayoutMobile />,
  children: [
    {
      path: "/*",
      element: <Dashboard />
    },
    {
      path: "/",
      element: <Dashboard />
    },
    {
      path: "/login",
      element: <Login />
    },
    {
      path: "/productDetails/:id",
      element: <ProductDetail />
    },

    // {
    //   path: "/email",
    //   element: <Email />
    // },
    {
      path: "/select-signup",
      element: <MetaWallet />
    },
    {
      path: "/socialLogin",
      element: <SocialLogin />
    },
    {
      path: "/forgetPassword",
      element: <ForgotPassword />
    },
    {
      path: "/signUp",
      element: <SignUp />
    },

    {
      path: "/mobile-verify-code",
      element: <VerifyCode />
    },
    {
      path: "/emailVerify",
      element: <VerifyEmailBefore />
    },
    {
      path: "/Verify",
      element: <VerifyEmail />
    }
  ]
};
export default MobileRoutes;
